import '~/features/ShopLoginButton/ShopLoginButton';

import {defineInitFunction} from '~/utils/defineInitFunction';

import {initCustomerAccountsSignUp} from './initCustomerAccountsSignUp';

(() =>
  defineInitFunction(
    'initCustomerAccountsSignUp',
    initCustomerAccountsSignUp,
  ))();
